<template>
  <div class="session-box">
    <div class="box">
      <div class="box-content">
        <div class="image-container">
          <initials-circle
            class="gray-border-circle"
            :first="firstInitial"
            :second="secondInitial"
          />
        </div>
        <div class="text-container">
          <p
            v-if="!showGreeting"
            class="name text-capitalize"
          >
            <strong>{{ patientGreetingName }}.</strong>
          </p>
          <h2 v-if="showGreeting">
            {{ $t("greeting") }} {{ greetingName }}!
          </h2>
          <p class="message">
            {{ message }}
          </p>
          <p
            v-if="false"
            class="questions-length"
          >
            {{ session.questionnaires.length }} Question<span v-if="session.questionnaires.length > 1">s</span>
          </p>
        </div>
      </div>
      <div
        v-if="session.questionnaires.length"
        class="actions-container"
      >
        <a
          href="#"
          class="btn btn-primary"
          @click.prevent="startSession"
        >
          <span v-if="sessionAlreadyStarted">
            {{ $t("resume") }}
          </span>
          <span v-else>
            {{ $t("start") }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InitialsCircle from '@/components/common/InitialsCircle'
import { DateDiffWithFormat } from '@/mixins/DateDiffWithFormat'
import moment from 'moment'
export default {
  name: 'SessionBox',
  components: {
    InitialsCircle
  },
  mixins: [DateDiffWithFormat],
  props: ['user', 'session', 'showGreeting'],
  data () {
    return {
      message: this.$tc('noSessionMessage', this.session.patient.respondentIsThePatient ? 1 : 2)
    }
  },
  computed: {
    sessionAlreadyStarted () {
      const sessionData = this.session.questionnaires[0]
      return sessionData.inProgress && (sessionData.currentPage > 0 || sessionData.currentQuestion > 0)
    },
    firstInitial () {
      return (this.session.patient && this.session.patient.initials) ? this.session.patient.initials[0] : ''
    },
    secondInitial () {
      return (this.session.patient && this.session.patient.initials) ? this.session.patient.initials[1] : ''
    },
    greetingName () {
      return (this.user.firstName) ? this.user.firstName : this.user.username
    },
    patientGreetingName () {
      return this.session.patient.name.split(' ')[0] + ' ' + this.session.patient.initials[1]
    },
    currentPage () {
      return this.session.questionnaires[0].currentPage ? this.session.questionnaires[0].currentPage : 0
    },
    ...mapGetters({
      singleMeasure: 'activeMeasure'
    })
  },
  created () {
    this.setMessage()
  },
  methods: {
    setMessage () {
      if (this.session.questionnaires.length) {
        this.message = this.getSessionMessage()
      }
    },
    getSessionMessage () {
      const dateString = this.session.questionnaires[0].dueDate
      const dueDate = this.getDateDiffWithFormat(dateString)
      const preposition = this.getPreposition(dateString, dueDate)
      const forSomeone = (this.session.patient.respondentIsThePatient) ? '' : `for ${this.session.patient.name} `
      return `${this.$t('owlSessionMessage')} ${forSomeone} ${preposition} ${dueDate}.`
    },
    getPreposition (dateString, dueDate) {
      if (this.sessionIsInPast(dateString)) {
        return this.$t('from')
      }
      return this.sessionIsToday(dueDate) ? '' : this.$t('for')
    },
    sessionIsToday (sessionDueDate) {
      return sessionDueDate.toLowerCase().indexOf('today') !== -1
    },
    sessionIsInPast (sessionDate) {
      const now = moment()
      const diff = now.diff(sessionDate)
      return diff > 0 && diff > 60000 * 60 * 24
    },
    startSession () {
      const loader = this.$loading.show()
      const params = { id: this.session.questionnaires[0].id, pageId: this.currentPage }
      this.$store.dispatch('GET_SINGLE_MEASURE', params).then(() => {
        this.getSingleMeasureCallback()
      }).catch(() => {
        this.$router.push({ name: 'PageNotFound' })
      }).finally(() => {
        loader.hide()
      })
    },
    getSingleMeasureCallback () {
      let clientMeasureId = this.session.questionnaires[0].id
      let pageId = this.currentPage
      if (this.singleMeasure.redirect && !this.singleMeasure.clientMeasureId) {
        return this.finalizeSession()
      }

      if (this.singleMeasure.redirect) {
        clientMeasureId = this.singleMeasure.clientMeasureId
        pageId = 0
      }

      this.$router.push({
        name: 'SingleMeasure',
        params: { measureId: clientMeasureId, pageId: pageId }
      })
    },
    finalizeSession () {
      return this.$store.dispatch('FINISH_SESSION', true).then(() => {
        this.$router.push({ name: 'SessionResultSent' })
      })
    }
  }
}
</script>
