import moment from 'moment'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'

export const DateDiffWithFormat = {
  mixins: [DateTimeHelper],
  methods: {
    getDateDiffWithFormat: function (dateString) {
      const date = moment(dateString)

      if (this.$isToday(dateString)) {
        return this.$t('today').toLowerCase()
      } else if (this.$isBeforeToday(dateString)) {
        return `${date.format('dddd MMMM DD')}`
      } else if (this.$isAfteroday(dateString)) {
        // If dif is less than 6 days print day name
        if ((date.diff(moment(), 'days')) <= 6) {
          return `${date.format('dddd')}`
        } else {
          return `${date.format('dddd MMMM DD')}`
        }
      } else {
        return dateString ? this.$t('today').toLowerCase() : ''
      }
    }
  }
}
