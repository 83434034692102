<template>
  <div
    :key="Math.random()"
    class="sessions-container"
    :class="`layout-${sessions ? sessions.length : 1}-questions`"
  >
    <div class="sessions-box-container">
      <session-box
        v-for="(session, index) in sessions"
        v-show="!checkingSessions"
        :key="session.patient.id+'-'+index"
        :user="user"
        :session="session"
        :show-greeting="sessions.length == 1"
      />
    </div>
    <div class="image-footer">
      <img
        class="footer-img"
        :src="imageUrl"
        alt="Logo"
      >
    </div>
  </div>
</template>

<script>
import SessionBox from '@/components/measures/SessionBox'
import { SessionsHelpers } from '@/mixins/SessionsHelpers'
import moment from 'moment'

export default {
  name: 'Session',
  components: {
    SessionBox
  },
  mixins: [SessionsHelpers],
  data () {
    return {
      checkingSessions: false,
      user: null
    }
  },
  computed: {
    imageUrl () {
      return `/logos/${this.$store.getters.generalSettings.custom_logo || '/logos/clear.png'}`
    },
    sessions () {
      const sessions = this.$store.getters.userSessions
      const ownSessions = []
      const sessionsWithDueDate = []
      const sessionsWithOutDueDate = []

      // separate own sessions, sessions with due date ( for future ordering ) and sessions without questionnaires
      sessions.forEach(s => {
        if (s.patient.respondentIsThePatient) {
          ownSessions.push(s)
        } else if (s.questionnaires.length) {
          sessionsWithDueDate.push(s)
        } else {
          sessionsWithOutDueDate.push(s)
        }
      })
      // Order sessions with duedate by duedate
      sessionsWithDueDate.sort((a, b) => {
        const aTimeStapm = moment(a.questionnaires[0].dueDate).valueOf()
        const bTimeStapm = moment(b.questionnaires[0].dueDate).valueOf()
        if (aTimeStapm < bTimeStapm) { return -1 }
        if (aTimeStapm > bTimeStapm) { return 1 }
        return 0
      })
      // build array (ownSession always first), patients sessions with due date second and patient without questionnaires last
      return [].concat(ownSessions, sessionsWithDueDate, sessionsWithOutDueDate)
    }
  },
  created () {
    const loader = this.$loading.show()
    this.checkingSessions = true
    this.$store.dispatch('GET_SESSIONS').then(() => {
      this.checkingSessions = false
      if (!this.hasQuestionnaires) {
        return this.$router.push({ name: 'NoSessions' })
      }
    }).finally(() => {
      loader.hide()
    })
    this.user = this.$store.getters.loggedInUser
  }
}
</script>
